import {useEffect} from 'react';
import Head from 'next/head';

import {useTranslate} from '@eksab/i18n';

import {Background, Container, Login, AuthenticationRequiredAlert} from '@eksab/features/auth/components';
import {dehydrate, QueryClient} from '@tanstack/react-query';
import {GetStaticProps} from 'next';
import {setApiLocale} from '@eksab/api';
import {useRegionSettings} from '@eksab/hooks/useRegionSettings';

export default function LoginPage() {
  const t = useTranslate();

  useEffect(() => {
    mixpanel?.track('Page View', {page: 'Login'});
  }, []);

  return (
    <>
      <Head>
        <meta name="description" content="Predict game score and win prizes | Eksab" />
        <title>{`${t('app.actions.login')} | ${t('app.name')}`}</title>
      </Head>

      <AuthenticationRequiredAlert />

      <Background>
        <Container>
          <Login />
        </Container>
      </Background>
    </>
  );
}

export const getStaticProps: GetStaticProps = async ({locale}) => {
  const queryClient = new QueryClient();

  setApiLocale(locale);

  await queryClient.prefetchQuery([useRegionSettings.queryKey, locale], useRegionSettings.queryFn);

  return {
    props: {
      dehydratedState: dehydrate(queryClient),
    },
    revalidate: 60 * 60 * 3,
  };
};
